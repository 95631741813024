<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters align="center">
        <v-col>
          <v-tabs v-model="activeTab">

            <v-tab>
              {{ $t('shippingAddresses') }}
            </v-tab>
            <v-tab>
              {{ $t('billingAddresses') }}
            </v-tab>
            <v-tab>
              {{ $t('contacts') }}
            </v-tab>
            <v-tab>
              {{ $t('overweightSpecs') }}
            </v-tab>

          </v-tabs>
        </v-col>
        <v-col cols="auto">
          <Icon
          icon="mdi-plus"
          tooltipColor="primary"
          :tooltipText="addButtonText"
          :small="false"
          tabindex="0"
          @icon-clicked="showCreateDialog(activeTab)"
          :elementId="`account-form-tab-add-${activeTabKebabCase}`"
          :disabled="addButtonDisabled"/>
        </v-col>
      </v-row>

      <v-tabs-items v-model="activeTab" touchless>

        <v-tab-item>
          <v-container fluid>
            <v-row v-if="shippingAddresses.length > 0">
              <v-col v-for="(address, index) in shippingAddresses" :key="`shipping-${index}`" cols="12" lg="4">
                <AddressCard
                :address="address"
                :accountId="account.accountId"
                @edit-address-clicked="editAddress"
                @delete-address-clicked="addressDeleted"/>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                {{ $t('noShippingAddressesAdded') }}
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
              <v-row v-if="billingAddresses.length > 0">
                <v-col v-for="(address, index) in billingAddresses" :key="`shipping-${index}`" cols="12" lg="4">
                  <AddressCard
                  :address="address"
                  @edit-address-clicked="editAddress"
                  @delete-address-clicked="addressDeleted"/>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  {{ $t('noBusinessAddressesAdded') }}
                </v-col>
              </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <v-row v-if="account.contacts.length > 0">
              <v-col v-for="(contact, index) in account.contacts" :key="index" cols="12" lg="4">
                <ContactCard
                showCrud
                :contact="contact"
                @click:edit="editContact"
                @click:delete="deleteContact"/>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                {{ $t('noContactsAdded') }}
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid class="overweight-penalty-thresholds-table">
            <v-card v-if="overweightThresholds.length > 0" style="max-width: 536px;" outlined>
              <v-card-title>
                <v-icon color="black" class="mr-2">{{ weightAlert.path }}</v-icon>
                <span class="subtitle-1">{{ $t('penaltyThresholds') }}</span>
                <v-spacer/>
                <Icon
                  icon="mdi-delete-forever"
                  iconColor="error"
                  tooltipColor="error"
                  :tooltipText="$t('delete')"
                  @icon-clicked="penaltyDefinitionRemoved"
                />
              </v-card-title>
              <v-card-text class="pt-0">
                <v-data-table
                dense
                mobile-breakpoint="450"
                :items="overweightThresholdItems"
                :sort-by="['status', 'threshold']"
                :items-per-page="-1"
                hide-default-footer
                :headers="overweightThresholdHeaders"
                >
                  <template #item.threshold="{item}">
                    <v-container fluid class="px-4 py-0" v-if="item.status !== ThresholdStatus.Placeholder">
                      <v-text-field
                      class="py-4"
                      v-if="item.status === ThresholdStatus.Unsaved"
                      :label="$t('threshold')"
                      dense
                      :rules="[rules.required, rules.integerWithCommasBetweenOrBlank(minPenaltyPounds, maxPenaltyPounds)]"
                      v-model="item.threshold"
                      :key="item.status"
                      autofocus
                      v-mask="integerWithCommasInputMask(item.threshold)"
                      maxLength="7"
                      >
                      </v-text-field>
                      <span v-else>{{ numberWithCommas(item.threshold, 0) }}</span>
                    </v-container>
                  </template>
                  <template #item.penaltyAmount="{item}">
                    <v-container fluid class="px-4 py-0" v-if="item.status !== ThresholdStatus.Placeholder">
                      <v-text-field
                      class="py-4"
                      v-if="item.status === ThresholdStatus.Unsaved"
                      :label="$t('penalty')"
                      dense
                      :rules="[rules.required, rules.twoDecimalPlacesOrFewer]"
                      v-model="item.penaltyAmount"
                      type="number"
                      hide-spin-buttons
                      :key="item.status"
                      prefix="$"
                      >
                      </v-text-field>
                      <span v-else>{{ formatMoney(item.penaltyAmount) }}</span>
                    </v-container>
                  </template>
                  <template #item.actionRight="{item}">
                    <Icon v-if="item.status !== ThresholdStatus.Placeholder"
                    icon="mdi-close"
                    iconColor="rgba(0,0,0,0.6)"
                    tooltipColor="black"
                    margin=""
                    :tooltipText="$t('remove')"
                    @icon-clicked="deleteLocalThreshold(item)"/>
                    <Icon v-else
                    tabindex="0"
                    icon="mdi-plus"
                    :tooltipText="$t('newThreshold')"
                    :small="false"
                    @icon-clicked="showCreateDialog(activeTab)"
                    margin=""/>
                  </template>
                  <template #item.actionLeft="{item}">
                    <Icon v-if="item.status === ThresholdStatus.Unsaved"
                    tabindex="0"
                    icon="mdi-check-circle"
                    margin=""
                    :small="false"
                    :tooltipText="$t('confirm')"
                    :disabled="confirmThresholdDisabled(item)"
                    @icon-clicked="confirmThreshold(item)"/>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <span v-else>
              {{ $t('noOverweightSpecificationsAdded') }}
            </span>
          </v-container>
        </v-tab-item>

      </v-tabs-items>

    </v-col>
    <v-dialog v-model="addressFormDialog" max-width="60%">
      <AddressForm
      :type="addressType"
      v-if="addressFormDialog"
      :propAddress="focusedAddress"
      @address-created="addressCreated"
      @address-updated="addressUpdated"
      @close="closeDialogs"/>
    </v-dialog>
    <v-dialog v-model="contactFormDialog" width="800px">
      <ContactForm
      v-if="contactFormDialog"
      @close="closeDialogs"
      :propContact="focusedContact"
      :dtrDisabled="account?.isExternal !== undefined && !account.isExternal"
      @contact-updated="contactUpdated"
      @contact-created="contactCreated"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { AddressType } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules.js'
import { maxBy, minBy, remove } from 'lodash'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { overweightThresholdHeaders } from '@/headers/Account'
import { mask } from 'vue-the-mask'
import { integerWithCommasInputMask } from '@/utils/InputMasks'
import { weightAlert } from '@/images/AngelSvgLibrary'

const Tab = {
  ShippingAddresses: 0,
  BillingAddresses: 1,
  Contacts: 2,
  OverweightSpecs: 3
}

const ThresholdStatus = { // Determines whether threshold is in edit mode or read-only mode locally. Unrelated to server/database
  Saved: 0,
  Unsaved: 1,
  Placeholder: 2
}

const MAX_THRESHOLD_COUNT = 5

const MIN_PENALTY_POUNDS = 80_000
const MAX_PENALTY_POUNDS = 150_000

const MAX_PENALTY_VALUE = 999.99

export default {
  name: 'AccountTabs',

  components: {
    AddressCard: () => import('./address/AddressCard.vue'),
    AddressForm: () => import('./address/AddressForm.vue'),
    ContactForm: () => import('@/components/account/contacts/ContactForm.vue'),
    ContactCard: () => import('@/components/account/contacts/ContactCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  directives: {
    mask
  },

  data: () => ({
    weightAlert,
    rules: fieldRules.rules,
    Tab: Tab,
    ThresholdStatus: ThresholdStatus,

    activeTab: Tab.ShippingAddresses,

    addressFormDialog: false,
    addressType: AddressType.ShipTo.value,
    focusedAddress: undefined,

    contactFormDialog: false,
    focusedContact: undefined,

    minPenaltyPounds: MIN_PENALTY_POUNDS,
    maxPenaltyPounds: MAX_PENALTY_POUNDS,
    overweightThresholds: [],
    penaltyDefinitionId: undefined,
    dialogId: uniqueDialogId('account-form-tabs'),
    thresholdIdToBeDeleted: undefined
  }),

  watch: {
    addressFormDialog (val) {
      if (!val) {
        this.focusedAddress = undefined
      }
    },

    contactFormDialog (val) {
      if (!val) {
        this.focusedContact = undefined
      }
    }
  },

  created () {
    if (this.account?.penalty) {
      this.overweightThresholds = this.account.penalty.thresholds
        .map(p => ({
          ...p,
          status: ThresholdStatus.Saved
        }))
      this.penaltyDefinitionId = this.account.penaltyDefinitionId
    }
  },

  computed: {
    shippingAddresses () {
      return this.account.addresses.filter(a => a.address.type === AddressType.ShipTo.value)
    },
    billingAddresses () {
      return this.account.addresses.filter(a => a.address.type === AddressType.BillTo.value)
    },
    addButtonText () {
      switch (this.activeTab) {
        case this.Tab.Contacts:
          return this.$t('newContact')
        case this.Tab.OverweightSpecs:
          return this.$t('newPenalty')
        default:
          return this.$t('newAddress')
      }
    },
    addButtonDisabled () {
      return this.activeTab === Tab.OverweightSpecs &&
      this.overweightThresholds.length > 0
    },
    overweightThresholdHeaders () {
      return overweightThresholdHeaders(true)
    },
    editingAccount () {
      return !!this.account.accountId
    },
    accountHasPreexistingPenaltyDefinition () {
      return !!this.penaltyDefinitionId
    },
    overweightThresholdItems () {
      if (this.overweightThresholds.length <= MAX_THRESHOLD_COUNT && this.overweightThresholds.every(owt => owt.status === ThresholdStatus.Saved)) {
        return this.overweightThresholds.concat([{ status: ThresholdStatus.Placeholder }])
      }
      return this.overweightThresholds
    },
    activeTabKebabCase () {
      switch (this.activeTab) {
        case 0: return 'shipping-address'
        case 1: return 'billing-address'
        case 2: return 'contacts'
        default: return undefined
      }
    }
  },

  methods: {
    ...mapActions('account', ['createAccountPenaltyDefinition',
      'deleteAccountPenaltyDefinition',
      'createAccountPenaltyThreshold',
      'deleteAccountPenaltyThreshold'
    ]),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    formatMoney,
    numberWithCommas,
    remove,
    integerWithCommasInputMask,

    showCreateDialog (tab) {
      switch (tab) {
        case this.Tab.Contacts:
          this.contactFormDialog = true
          return

        case this.Tab.OverweightSpecs: {
          if (this.overweightThresholds.every(owt => owt.status !== this.ThresholdStatus.Unsaved)) {
            this.overweightThresholds = this.overweightThresholds.concat([{
              threshold: this.generateThreshold(),
              penaltyAmount: this.generatePenalty(),
              status: this.ThresholdStatus.Unsaved
            }])
          }
          return
        }

        default:
          this.addressFormDialog = true
      }

      if (tab === 0 || tab === 1) {
        this.addressType = tab
        this.addressFormDialog = true
      } else {
        this.contactFormDialog = true
      }
    },

    // Addresses
    editAddress (address) {
      this.addressType = address.address.type
      this.focusedAddress = address
      this.addressFormDialog = true
    },

    addressDeleted (address) {
      const index = this.findIndexWithProperties(address)
      const payload = {
        address: address,
        index: index
      }
      this.$emit('address-deleted', payload)
    },

    addressCreated (addresses) {
      this.$emit('address-created', [...addresses])
      this.closeDialogs()
    },

    addressUpdated (address) {
      const index = this.findIndexWithProperties(this.focusedAddress.address)
      const payload = {
        address: address,
        index: index
      }
      this.$emit('address-updated', payload)
      this.closeDialogs()
    },

    findIndexWithProperties (address) {
      return this.account.addresses.findIndex(a => {
        return a.address.addressLine1 === address.addressLine1 &&
          a.address.addressLine2 === address.addressLine2 &&
          a.address.postalCode === address.postalCode &&
          a.address.city === address.city &&
          a.address.state === address.state &&
          a.address.type === address.type
      })
    },

    // Contacts
    editContact (contact) {
      this.focusedContact = contact
      this.contactFormDialog = true
    },

    deleteContact (contact) {
      const index = this.findContactWithInfo(contact.person)
      this.$emit('contact-deleted', {
        contact: contact,
        index: index
      })
    },

    contactCreated (contact) {
      this.$emit('contact-created', contact)
      this.closeDialogs()
    },

    contactUpdated (contact) {
      const index = this.findContactWithInfo(this.focusedContact.person)
      this.$emit('contact-updated', {
        contact: contact,
        index: index
      })
      this.closeDialogs()
    },

    findContactWithInfo (contact) {
      return this.account.contacts.findIndex(c => {
        return c.person.firstName === contact.firstName &&
        c.person.lastName === contact.lastName &&
        c.person.email === contact.email &&
        c.person.phoneNumber === contact.phoneNumber
      })
    },

    closeDialogs () {
      this.addressFormDialog = false
      this.contactFormDialog = false
    },

    // Overweight Penalties
    async confirmThreshold (item) {
      const penaltyAmount = parseFloat(item.penaltyAmount)
      const threshold = parseInt(item.threshold.toString().replace(',', ''))

      if (this.validThreshold({ threshold, penaltyAmount })) {
        item.penaltyAmount = penaltyAmount
        item.threshold = threshold
      } else {
        return
      }

      item.status = ThresholdStatus.Saved
      this.$emit('thresholds-changed', this.overweightThresholds)
    },

    deleteLocalThreshold (item) {
      this.overweightThresholds = this.overweightThresholds.filter(owt => owt.status !== item.status || owt.threshold !== item.threshold)
      this.$emit('thresholds-changed', this.overweightThresholds)
    },

    confirmThresholdDisabled (item) {
      const penaltyAmount = parseFloat(item.penaltyAmount)
      const threshold = parseInt(item.threshold)

      return !penaltyAmount > 0 ||
      !threshold > 0 ||
      this.overweightThresholds.some(owt => owt.threshold === threshold && owt.status === ThresholdStatus.Saved)
    },

    generateThreshold () {
      if (this.overweightThresholds.length === 0) return 0
      const largestThreshold = maxBy(this.overweightThresholds, 'threshold').threshold
      if (this.overweightThresholds.length === 1) return largestThreshold + 1000
      const secondLargestThreshold = maxBy(
        this.overweightThresholds.filter(owt => owt.threshold !== largestThreshold),
        'threshold').threshold

      return largestThreshold + (largestThreshold - secondLargestThreshold)
    },

    generatePenalty () {
      if (this.overweightThresholds.length === 0) return 0
      const largestThresholdPenalty = maxBy(this.overweightThresholds, 'threshold').penaltyAmount
      if (this.overweightThresholds.length === 1) return largestThresholdPenalty
      const secondLargestThresholdPenalty = maxBy(
        this.overweightThresholds.filter(owt => owt.penaltyAmount !== largestThresholdPenalty),
        'threshold').penaltyAmount

      return (largestThresholdPenalty + (largestThresholdPenalty - secondLargestThresholdPenalty)).toFixed(2)
    },

    validThreshold ({ threshold, penaltyAmount }) {
      // Penalty must be larger than all smaller thresholds' penalties
      const lesserThresholds = this.overweightThresholds.filter(owt => owt.status === ThresholdStatus.Saved && parseInt(owt.threshold) < threshold)
      const minimumPenalty = lesserThresholds.length > 0 ? parseFloat(maxBy(lesserThresholds, 'threshold').penaltyAmount) : 0.00

      const greaterThresholds = this.overweightThresholds.filter(owt => owt.status === ThresholdStatus.Saved && parseInt(owt.threshold) > threshold)
      const maximumPenalty = greaterThresholds.length > 0 ? parseFloat(minBy(greaterThresholds, 'threshold').penaltyAmount) : 999.99

      if (threshold > MAX_PENALTY_POUNDS || threshold < MIN_PENALTY_POUNDS) {
        this.setSnackError(this.$t('thresholdMustBeBetween', { min: numberWithCommas(MIN_PENALTY_POUNDS), max: numberWithCommas(MAX_PENALTY_POUNDS) }))
      } else if (this.overweightThresholds
        .filter(owt => owt.status === ThresholdStatus.Saved)
        .some(owt => owt.threshold === threshold)) {
        this.setSnackError(this.$t('thresholdMustBeUnique'))
      } else if (penaltyAmount === 0) {
        this.setSnackError(this.$t('nonzeroPenaltyRequired'))
      } else if (penaltyAmount > MAX_PENALTY_VALUE) {
        this.setSnackError(this.$t('penaltyMustNotExceed'))
      } else if (penaltyAmount <= minimumPenalty || (!!maximumPenalty && penaltyAmount >= maximumPenalty)) {
        this.setSnackError(this.$t('penaltyAmountForLargerWeightMustBeLarger'))
      } else {
        return true
      }
      return false
    },

    penaltyDefinitionRemoved () {
      this.overweightThresholds = []
      this.$emit('thresholds-changed', this.overweightThresholds)
    }
  }
}
</script>

<style>
.overweight-penalty-thresholds-table .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0px !important;
}
</style>
