<template>
  <v-container fluid>
      <v-row>
        <v-col cols="auto" class="pa-0">
          <Selector
          :label="$t('selectEntity')"
          class="mt-2"
          :items="entities"
          @item-selected="entitySelected"
          variant="outlined"/>
        </v-col>
        <v-spacer/>
        <v-col align="right" cols="3">
          <v-row class="mb-n6">
            <v-col align="left">
              <span class="title">
                {{ $t('totalAccrued') }}:
              </span>
            </v-col>
            <v-col align="right">
              <span class="title">
                {{ formatMoney(totalAccrual) }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-n1 mb-2">
            <v-col align="left">
              <span class="subtitle-1">
                {{ $t('totalDepleted') }}:
              </span>
            </v-col>
            <v-col align="right">
              <span class="subtitle-1">
                {{ formatMoney(totalDepletion) }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    <v-row>
      <v-expansion-panels multiple popout v-model="openPanels" tile class="pa-1">
        <v-expansion-panel v-for="(journalEntry, index) in journalEntriesByEntity" :key="`journalEntries-${index}`">
          <v-expansion-panel-header>
            <v-row dense>
              <v-col cols="auto">
                <v-icon small >
                  mdi-account
                </v-icon>
                {{addNameWithCode(journalEntry.accountName, journalEntry.accountCode)}}
              </v-col>
            </v-row>
            <template #actions>
              <v-container>
                <v-row justify="end">
                {{getTotalAmountForRegisterPanel(journalEntry)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalWeightForRegisterPanel(journalEntry)}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <RegisterCard
              :register="journalEntry"
              :isByproduct="isByproduct"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { getEntities, getFinancialsByEntity, AccrualsHelpers } from '@/utils/RegisterHelpers'
import { TemplateSpecialization } from '@/utils/Enumerations'

export default {
  name: 'Register',

  props: {
    registerObject: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    }
  },

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    RegisterCard: () => import('@/components/settlements/accruals-module/register/RegisterCard.vue')
  },

  data: () => ({
    openPanels: [],
    entities: [],
    selectedEntity: undefined
  }),

  mounted () {
    this.getEntities()
  },

  watch: {
    entities: {
      handler (val) {
        if (val.length > 0) {
          this.selectedEntity = val[0]
        }
      },
      deep: true
    },
    journalEntriesByEntity: {
      handler (val) {
        this.openPanels = val?.length === 1 ? [0] : []
      }
    }
  },

  computed: {
    journalEntries () {
      const { journalEntries } = this.registerObject
      if (journalEntries === undefined) { return [] }

      return journalEntries
    },

    journalEntriesByEntity () {
      if (this.selectedEntity === undefined) { return this.journalEntries }
      return getFinancialsByEntity(this.journalEntries, this.selectedEntity, 2)
    },

    totalAccrual () {
      return this.journalEntriesByEntity.flatMap(je => je.activities).reduce((total, act) => total + act.amount, 0) +
        this.journalEntriesByEntity.flatMap(je => je.correctionFinancials).reduce((total, corr) => total + corr.amount, 0)
    },

    totalDepletion () {
      return this.journalEntriesByEntity.flatMap(je => je.activities.filter(a => a.templateSpecialization === TemplateSpecialization.Depletion.value)).reduce((total, act) => total + act.amount, 0) +
        this.journalEntriesByEntity.flatMap(je => je.correctionFinancials.filter(a => a.templateSpecialization === TemplateSpecialization.Depletion.value)).reduce((total, corr) => total + corr.amount, 0)
    }
  },

  methods: {
    formatMoney,
    getEntities () {
      this.entities = getEntities(this.journalEntries, 2)
    },

    entitySelected (entity) {
      this.selectedEntity = entity
    },

    getTotalAmountForRegisterPanel (journalEntry) {
      return AccrualsHelpers.totalStringForAccount(journalEntry)
    },

    getTotalWeightForRegisterPanel (journalEntry) {
      return AccrualsHelpers.totalWeightStringForAccount(journalEntry)
    }
  }
}
</script>
