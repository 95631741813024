<template>
  <v-card color="white" data-testid="contract-form" max-height="80vh">
    <v-card-title :class="contractColor">
      <span>
        {{titleText}}
      </span>
      <v-spacer/>
      <BaseDialogActions
      dialogId="contracts"
      hideRefresh
      @close="$emit('close')"/>
    </v-card-title>
    <v-card-text>
      <Dialog :stateId="dialogId" enforcePersistence>
        <AddActivitiesDialog
        v-if="addActivitiesDialog"
        :contractMode="contractMode"
        :includeBundles="contractMode.value !== 2"
        @cancel-activity="close"
        @create-activity="close"/>
      </Dialog>
      <FormWrapper
        testId="save-contract"
        strongValidation
        :buttonText="$t('createContract')"
        :buttonColor="contractColor"
        :disabled="fieldsNotEntered || isButtonDisabled"
        @submit="save"
      >
        <v-container fluid>
          <v-row v-if="contractMode.value === ContractMode.Byproducts.value">
            <v-col cols="6">
              <LoadableComponent :loading="loading">
                <v-select
                  v-model="selectedContractType"
                  color="black"
                  item-color="black"
                  :label="$t('contractType')"
                  :items="byproductContractTypes"
                  return-object
                  item-text="name">
                </v-select>
              </LoadableComponent>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col xs="12" sm="12" md="12" lg="6">
              <LoadableComponent :loading="loading">
                <AccountAutocomplete
                  data-testid="contract-account"
                  userSetting="contractAccount"
                  @account-chosen="accountChosen"
                  showCertified
                  :fetchTagsAndAccounts="false"
                ></AccountAutocomplete>
              </LoadableComponent>
            </v-col>
            <v-col cols="12" lg="6">
              <LoadableComponent :loading="loading">
                <AccountAutocomplete
                  data-testid="contract-FromAccount-account"
                  :onlyInternal="!fromAccountIsExternal"
                  :onlyExternal="fromAccountIsExternal"
                  :title="$t('fromAccount')"
                  @account-chosen="fromAccountChosen"
                  :prohibitedSet="destinationAccountId ? new Set([destinationAccountId]) : undefined"
                  :fetchTagsAndAccounts="false"/>
              </LoadableComponent>
            </v-col>
            <v-col cols="12" lg="6">
              <LoadableComponent :loading="loading">
                <AccountAutocomplete
                  data-testid="contract-destination-account"
                  :onlyInternal="contractMode.value === ContractMode.Transfers.value || contractTypeIsByproductPurchase"
                  :onlyExternal="contractMode.value !== ContractMode.Transfers.value && !contractTypeIsByproductPurchase"
                  userSetting="contractDestinationAccount"
                  :title="$t('destinationAccount')"
                  @account-chosen="destinationChosen"
                  :prohibitedSet="fromAccountId ? new Set([fromAccountId]) : undefined"
                  :fetchTagsAndAccounts="false"/>
              </LoadableComponent>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" lg="12">
              <LoadableComponent :loading="loading">
                <ContractInfo
                  :contractType="selectedContractType"
                  :contractMode.sync="contractMode"
                  :propContractInfo="contractInformation"
                  :editing="false"
                  :tract="tract"
                  :lockAsDraft="lockAsDraft"
                  @is-button-disabled="(val) => isButtonDisabled = val"
                  @contract-info-changed="contractInfoChanged"
                />
              </LoadableComponent>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { localToUTC } from '@/utils/DateFormatter.js'
import { ContractType, ContractMode } from '@/utils/Enumerations.js'
import fieldRules from '@/utils/rules.js'
import { colorClassForContractMode } from '@/utils/componentHelpers'
export default {
  name: 'ContractCreator',

  components: {
    AccountAutocomplete: () => import('../autocomplete/AccountAutocomplete'),
    ContractInfo: () => import('./ContractInfo'),
    AddActivitiesDialog: () => import('./AddActivitiesDialog'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  props: {
    contractMode: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    ContractMode,
    dialogId: 'contract-creator',
    contractCreated: true,
    acknowledgeDeprecation: false,
    tract: null,
    settingId: null,
    accountId: null,
    fromAccountId: null,
    destinationAccountId: null,
    isButtonDisabled: false,
    destinationAccount: null,
    createdContract: {},
    contractInformation: {
      effectiveDate: '',
      expirationDate: '',
      acceptsAnyLoad: false,
      requiresLogger: false,
      requiresExt1: false,
      requiresExt2: false,
      isDraft: true,
      type: 0,
      distance: 0,
      tareWeight: 0,
      allowPickupLoads: false
    },
    addActivitiesDialog: false,
    selectedContractType: undefined,
    rules: fieldRules.rules,
    loading: true
  }),

  computed: {
    ...mapGetters('user-settings', ['mutatedUserSettings']),
    ...mapGetters('user', ['companyInfo']),
    fieldsNotEntered () {
      const sharedFields = this.accountId === null ||
        this.destinationAccountId === null ||
        this.contractInformation.effectiveDate === '' ||
        this.contractInformation.expirationDate === ''

      if (this.selectedContractType.value === ContractType.Transfer.value || this.selectedContractType.value === ContractType.ByproductPurchase.value) {
        return sharedFields || this.fromAccountId === null
      }

      if (this.contractMode.value === ContractMode.Byproducts.value) {
        return sharedFields || this.selectedContractType === undefined
      }

      return sharedFields
    },

    byproductContractTypes () {
      return [
        ContractType.ByproductSale,
        ContractType.ByproductPurchase
      ]
    },

    contractTypeIsByproductPurchase () {
      return this.selectedContractType?.value === ContractType.ByproductPurchase.value
    },

    titleText () {
      switch (this.contractMode.value) {
        case ContractMode.Logs.value:
          return this.$t('createLogsContract')
        case ContractMode.Byproducts.value:
          return this.$t('createByproductContract')
        case ContractMode.Transfers.value:
          return this.$t('createTransferContract')
        case ContractMode.LogYardSale.value:
          return this.$t('createLogYardSaleContract')
        default: return ''
      }
    },

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    },

    lockAsDraft () {
      return this.companyInfo.requireApprovalForContractModifications
    },

    fromAccountIsExternal () {
      return this.selectedContractType.value === ContractType.ByproductPurchase.value
    }
  },

  beforeDestroy () {
    this.updateUserSettings(this.mutatedUserSettings)
  },

  async created () {
    this.loading = true
    switch (this.contractMode.value) {
      case ContractMode.Byproducts.value:
        this.selectedContractType = this.byproductContractTypes[0]
        break
      case ContractMode.Transfers.value:
        this.selectedContractType = ContractType.Transfer
        break
      case ContractMode.LogYardSale.value:
        this.selectedContractType = ContractType.LogYardSale
        break
    }

    try {
      await Promise.all([
        this.getAccounts(),
        this.getAccountTags()
      ])
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions('contract', ['createContract']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),

    accountChosen (val) { this.accountId = val?.accountId },
    fromAccountChosen (val) { this.fromAccountId = val?.accountId },
    settingChosen (val) { this.settingId = val?.settingId },

    async getAccounts () {
      await this.fetchAccounts()
    },

    async getAccountTags () {
      await this.fetchAccountTags()
    },

    closeSubdialogs () {
      this.addActivitiesDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    destinationChosen (destinationAccount) {
      this.destinationAccountId = destinationAccount?.accountId
      this.destinationAccount = destinationAccount
    },

    contractInfoChanged (contractInfo) {
      this.contractInformation = { ...contractInfo }
    },

    async save () {
      this.closeSubdialogs()

      if (!this.destinationAccount?.isExternal && this.selectedContractType.value === ContractType.ByproductSale.value) {
        this.setSnackError(this.$t('byproductSaleContractCannotBeInternal'))
        return
      }

      if (this.destinationAccount?.isExternal && this.selectedContractType.value === ContractType.ByproductPurchase.value) {
        this.setSnackError(this.$t('byproductPurchaseContractCannotBeExternal'))
        return
      }

      const contract = this.constructContract()
      const response = await this.createContract(contract)
      this.createdContract = response
      this.addActivitiesDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '500px', allowFullscreen: false })
    },

    constructContract () {
      const contract = {
        ...this.contractInformation,
        type: this.selectedContractType.value,
        accountId: this.accountId,
        destinationAccountId: this.destinationAccountId,
        fromAccountId: this.fromAccountId,
        tareWeight: parseInt(this.contractInformation.tareWeight),
        effectiveDate: localToUTC(this.contractInformation.effectiveDate),
        expirationDate: localToUTC(this.contractInformation.expirationDate)
      }

      return contract
    },

    close ({ shouldAddActivity, selectedBundle }) {
      this.closeSubdialogs()
      const createdContract = {
        contract: this.createdContract,
        shouldAddActivity: shouldAddActivity,
        selectedBundle: selectedBundle
      }

      this.$emit('contract-changed', createdContract)
    }
  }
}
</script>
