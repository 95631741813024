<template>
  <v-container fluid>
    <v-expansion-panels multiple popout v-model="openPanels" tile>
      <v-expansion-panel v-for="(accountAdvance, index) in filteredAdvances" :key="index">
        <v-expansion-panel-header>
          {{accountAdvance.accountName}}
          <v-spacer/>
          <template #actions>
            {{ formatMoney(accountAdvance.balance)}}
            <v-icon class="ml-2">mdi-chevron-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <AdvancePanelContent
          :dense="dense"
          :accountAdvance="accountAdvance"
          :asAtDate="asAtDate"
          @refresh="$emit('refresh')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
export default {
  name: 'AccountAdvancePanels',

  components: {
    AdvancePanelContent: () => import('./AdvancePanelContent.vue')
  },

  props: {
    search: String,
    advances: Array,
    dense: Boolean,
    asAtDate: String
  },

  computed: {
    ...mapGetters('advances', ['allAdvances']),
    filteredAdvances () {
      if (this.search) {
        return this.accountAdvances.filter((v) => {
          return v.accountName.toLowerCase().includes(this.search.toLowerCase()) || v.balance.toString().includes(this.search.toLowerCase())
        })
      } else {
        return this.accountAdvances
      }
    }
  },

  watch: {
    advances: {
      handler () {
        this.accountAdvances = []
        this.createAccountAdvances()
      },
      deep: true
    },
    filteredAdvances: {
      handler () {
        let total = 0
        if (!this.filteredAdvances) {
          this.$emit('advancesTotalUpdated', total)
        } else {
          total = this.filteredAdvances.reduce((acc, v) => acc + v.balance, 0)
          this.openPanels = this.filteredAdvances.length === 1 ? [0] : []
          this.$emit('advancesTotalUpdated', total)
        }
      },
      deep: true
    }
  },

  data: () => ({
    accountAdvances: [],
    accounts: [],
    openPanels: []
  }),

  created () {
    this.openPanels = this.filteredAdvances.length === 1 ? [0] : []
  },

  methods: {
    formatMoney,
    createAccountAdvances () {
      if (this.advances) {
        this.advances.forEach(advance => {
          const accountIndex = this.accountAdvances.findIndex(v => v.accountId === advance.payeeId)

          if (accountIndex > -1) {
            this.updateAccountAdvance(advance, accountIndex)
          } else {
            this.createAccountAdvance(advance)
          }
        })
      }
    },

    updateAccountAdvance (advance, index) {
      const accountAdvance = this.accountAdvances[index]
      accountAdvance.amount += advance.amount
      accountAdvance.balance += advance.balance
      accountAdvance.advances.push(advance)

      this.accountAdvances[index] = accountAdvance
    },

    createAccountAdvance (advance) {
      this.accountAdvances.push({
        accountId: advance.payeeId,
        accountName: advance.payeeName,
        advances: [advance],
        amount: advance.amount,
        balance: advance.balance
      })
    }
  }

}
</script>
