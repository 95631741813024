<template>
  <v-row>
    <v-col cols="12" md="6" lg="12">
      <DetailCard :title="$t('geography')">
        <v-row dense class="px-0">
          <v-col cols="12" class="mb-n6"
          v-if="tractLocationText">
            <v-text-field
            :label="$t('location')"
            outlined
            readonly
            dense
            data-testid="tract-info-location"
            :value="tractLocationText">
              <template #prepend-inner>
                <v-icon>mdi-map-marker</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" class="mb-n6">
            <v-text-field
            :label="$t('latitude')"
            data-testid="tract-info-latitude"
            outlined
            readonly
            dense
            :value="tract.spot.latitude">
            </v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" class="mb-n4">
            <v-text-field
            :label="$t('longitude')"
            data-testid="tract-info-longitude"
            outlined
            readonly
            dense
            :value="tract.spot.longitude">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters v-for="row in geographyRows.filter(r => r.value)" :key="row.key">
          <v-col >
            <Icon v-if="row.keyIcon" :icon="row.keyIcon.icon" :tooltipText="$t(row.keyIcon.iconTooltip)" margin="mx-0 mt-n1" iconColor="info" tooltipColor="info"></Icon>
            {{$t(row.key)}}:
          </v-col>
          <v-col cols="auto" :style="infoCardRightColumn">
            <span> {{row.value}} </span>
            <Icon v-if="row.valueIcon" :icon="row.valueIcon.icon" :tooltipText="row.valueIcon.iconTooltip" margin="mx-0 mt-n1" iconColor="info" tooltipColor="info"></Icon>
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
    <v-col cols="12" md="6" lg="12" v-if="precipitationData.xValues.length > 0">
      <DetailCard :title="precipitationChartTitle">
        <template #title.append>
          <Icon
          v-if="precipitationData.xValues.length > 0"
          icon="mdi-tape-measure"
          :small="false"
          :tooltipText="toggleUnitsTooltip"
          @icon-clicked="togglePrecipitationUnits"
          />
        </template>
        <BarChartSimple
        :data="precipitationData"
        />
      </DetailCard>
    </v-col>
  </v-row>
</template>

<script>
import { numberWithCommas } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { BarChart } from '../../../model/BarChart'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'TractLocation',
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    BarChartSimple: () => import('@/components/helper/BarChartSimple.vue'),
    DetailCard: () => import('@/components/core/DetailCard.vue')
  },

  props: {
    tract: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    units: ['imperial', 'metric'],
    pickupRange: '',
    precipitationData: { xValues: [] },
    mapHeight: '69vh',
    currentPrecipitationUnits: parseInt(localStorage.getItem(LocalStorageKeys.PRECIPITATION_CHART_UNITS)) || 0,
    loadingUnitToggle: false
  }),

  created () {
    this.pickupRange = this.formatMetersToMiles()
    this.getPrecipitationData()
  },

  computed: {
    subdivision () {
      if (this.tract.spot.countrySecondarySubdivision !== '' && this.tract.spot.countrySubdivision !== '') {
        return `${this.tract.spot.countrySecondarySubdivision}, ${this.tract.spot.countrySubdivision}`
      }
      return ''
    },
    precipitationChartTitle () {
      return this.currentPrecipitationUnits === this.units.indexOf('metric') ? this.$t('precipitationInMm') : this.$t('precipitationInInches')
    },

    tractLocationText () {
      const { countrySubdivision: state, countrySecondarySubdivision: county } = this.tract.spot
      if (state && county) {
        return `${county}, ${state}`
      }
      return undefined
    },

    loggerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('code'),
          value: 'code',
          align: 'center'
        }
      ]
    },

    landownerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('ownership'),
          value: 'ownership',
          align: 'right'
        }
      ]
    },

    infoCardRightColumn () {
      return this.$vuetify.breakpoint.xs ? 'text-align:right;' : ''
    },

    geographyRows () {
      const tract = this.tract
      const ns = this.$t('notSpecified')
      return [
        { key: 'loggingAccessibility', value: tract.loggingAccessibilityTag ? tract.loggingAccessibilityTag : ns },
        { key: 'mapAndParcelNumber', value: this.tract.mapAndParcelNumber || this.$t('notSpecified') },
        { key: 'acres', value: this.numberWithCommas(tract.acres) },
        { key: 'tractPickupRadius', value: this.pickupRange },
        {
          key: '24Hour',
          keyIcon: {
            icon: 'mdi-weather-pouring',
            iconTooltip: 'precipitation24H'
          },
          value: this.tract.rainfallDataUpdatedOn ? `${this.tract.singleDayRainfallInches}"` : undefined,
          valueIcon: {
            icon: 'mdi-update',
            iconTooltip: `${this.$t('lastUpdatedX', { x: moment.utc(this.tract.rainfallDataUpdatedOn).local().format('L LT') })}`
          }
        }
      ]
    },

    toggleUnitsTooltip () {
      return this.$t('changeToUnits', { units: this.$t(this.currentPrecipitationUnits === this.units.indexOf('metric') ? 'inches' : 'millimeters') })
    }

  },

  methods: {
    numberWithCommas,
    utcToLocalDate,
    ...mapActions('tract', ['fetchTract', 'fetchTractTickets', 'getWeatherForTract', 'fetchTractLandowners']),

    async getPrecipitationData () {
      try {
        const today = new Date()
        const weatherData = await this.getWeatherForTract({
          latitude: this.tract.spot.latitude,
          longitude: this.tract.spot.longitude,
          startDate: new Date(today - 6 * 86400000).toISOString().slice(0, 10),
          endDate: today.toISOString().slice(0, 10),
          units: this.units[this.currentPrecipitationUnits]
        }, {})
        const weatherDataResult = weatherData.results
          .map(d => {
            return {
              date: new Date(Date.parse(d.date))
                .toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }),
              precipitation: d.precipitation.value
            }
          })
        this.precipitationData = new BarChart(weatherDataResult, 'date', 'precipitation', 'precipitation')
      } catch (e) {
        this.precipitationData = { xValues: [] }
      }
    },

    async togglePrecipitationUnits () {
      if (!this.loadingUnitToggle) {
        this.currentPrecipitationUnits = this.currentPrecipitationUnits === this.units.indexOf('imperial') ? this.units.indexOf('metric') : this.units.indexOf('imperial')
        localStorage.setItem(LocalStorageKeys.PRECIPITATION_CHART_UNITS, this.currentPrecipitationUnits)
        this.precipitationData = await this.getPrecipitationData()
        this.loadingUnitToggle = false
      }
    },

    formatMetersToMiles () {
      var miles
      switch (this.tract.pickupRange) {
        case 402:
          miles = '¼ Mile'
          break
        case 804:
          miles = '½ Mile'
          break
        case 1207:
          miles = '¾ Mile'
          break
        case 1609:
          miles = '1 Mile'
          break
        case 3218:
          miles = '2 Miles'
          break
        case 4828:
          miles = '3 Miles'
          break
      }

      return miles
    },

    tractRainfallString () {
      const lastUpdated = moment.utc(this.tract.rainfallDataUpdatedOn).local().format('L LT')
      return `${this.tract.singleDayRainfallInches}" (${this.$t('lastUpdatedX', { x: lastUpdated })})`
    }
  }
}
</script>
