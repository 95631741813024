<template>
<span>
 <v-row>
  <v-col cols="12">
    <span class="mr-2 subtitle-1">{{ `Entities (${entities.length})` }}</span>
    <Icon
      iconColor="primary"
      icon="mdi-plus"
      dataTestId="entity-create-button"
      :small="false"
      :tooltipText="$t('createEntity')"
      @icon-clicked="openEntityForm(undefined)"
      :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
    />
  </v-col>
  <v-col cols="12" class="pa-0 ma-0">
    <v-tabs v-model="activeTab" center-active class="card-tabs">
      <v-tab
        v-for="(entity,index) in entities"
        :data-testId="`tab-${entity.name.replaceAll(' ', '-')}`"
        :key="index"
      >
        {{ entity.name }}
        <v-spacer />
        <div class="ml-2">
          <Icon
            :iconColor="activeTab === index ? 'success' : '#aaa'"
            :tooltipColor="activeTab === index ? 'success' : '#aaa'"
            icon="mdi-pencil"
            dataTestId="entity-edit-button"
            :tooltipText="$t('editEntity')"
            @icon-clicked="openEntityForm(entity)"
            :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
          />
        </div>
        <Icon
          icon="mdi-delete"
          :iconColor="activeTab === index ? 'error' : '#aaa'"
          :tooltipColor="activeTab === index ? 'error' : '#aaa'"
          :tooltipText="$t('deleteEntity')"
          dataTestId="entity-delete-button"
          @icon-clicked="openEntityDelete(entity.businessEntityId)"
          :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
        />
      </v-tab>
    </v-tabs>
    <v-card outlined style="border-radius: 0px 0px 4px 4px">
      <v-window v-model="activeTab">
        <v-window-item
          v-for="(entity,index) in entities"
          :key="index"
          center-active
          :transition="false"
          disabled
        >
          <v-card flat tile :data-testId="`tab-content-${entity.name.replaceAll(' ', '-')}`">
            <v-card-text>
              <v-row class="mt-1">
                <v-col cols="3">
                  <v-text-field :label="$t('name')" :value="entity.name" readonly :disabled="!entity.name" outlined color="grey darken-1"/>
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="$t('exportCode')" :value="entity.exportCode ?? $t('notAvailable')" readonly :disabled="!entity.exportCode" outlined color="grey darken-1"/>
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="$t('account')" :value="entity.accountName ?? $t('notAvailable')" readonly :disabled="!entity.accountName" outlined color="grey darken-1"/>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xs="12">
                  <span class="mr-2 subtitle-1">{{`Locations (${entity.locations.length})`}}</span>
                  <Icon
                    icon="mdi-plus"
                    iconColor="primary"
                    dataTestId="entity-create-location-form"
                    :small="false"
                    :tooltipText="$t('createLocation')"
                    @close="resetDialogs"
                    @icon-clicked="openLocationForm(entity.businessEntityId, false)"
                    :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xs="12">
                  <Location
                    data-testid="location"
                    :entity="entity"
                    :openPanel="openPanel"
                    :entityTab="activeTab"
                    :entityIndex="index"
                    @location-mutated="closeLocationForm"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>
  </v-col>
    <Dialog :stateId="dialogId" maxWidth="80vw" @close="resetDialogs">
      <LocationForm
        v-if="locationForm"
        :businessEntityId="propBusinessEntityId"
        :companyInfo="companyInfo"
        @location-mutated="closeLocationForm"
        @close="resetDialogs(false)"
      />
      <EntityForm
        v-if="entityForm"
        :propEntity="propEntity"
        @entity-created="entityCreated"
        @entity-updated="entityUpdated"
      />
      <AccountForm
        v-if="accountForm"
        @account-id="resetDialogs(true)"
      />
      <AddLocationEntityDialog
        v-if="addLocationEntity"
        @cancel-location="closeAddLocationEntityDialog()"
        @create-location="openLocationForm(propEntity.businessEntityId, true)"
      />
      <ConfirmDelete
        v-if="deleteEntityId !== undefined"
        :title="$t('entity')"
        @delete="deleteEntity(deleteEntityId)"
        @cancel-delete="resetDialogs"
      />
    </Dialog>
  </v-row>
</span>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'CompanyEntities',

  components: {
    LocationForm: () => import('./LocationForm.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    Location: () => import('./Locations.vue'),
    AccountForm: () => import('@/components/account/AccountForm.vue'),
    EntityForm: () => import('./EntityForm.vue'),
    AddLocationEntityDialog: () => import('./AddLocationEntityDialog.vue')
  },

  props: {
    businessEntities: {
      type: Array,
      required: true
    },
    companyInfo: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    dialogId: 'company-entities',
    entities: [],
    openPanel: undefined,
    locationForm: false,
    entityForm: false,
    accountForm: false,
    deleteEntityId: undefined,
    addLocationEntity: false,
    propEntity: undefined,
    createdAccount: undefined,
    updatedExportCode: undefined,
    propBusinessEntityId: undefined,
    creatingDefaultAccount: false,
    activeTab: undefined,
    UserClaims
  }),

  created () {
    this.entities = this.businessEntities.map(b => b)
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('user', ['deleteBusinessEntity', 'updateBusinessEntity']),
    ...mapActions('account', ['fetchAccounts']),
    userAssignedClaim,

    entityCreated (entity) {
      this.resetDialogs(true)
      this.addLocationEntity = true
      this.propEntity = entity
      this.$emit('entity-mutated')
    },

    entityUpdatedInline (entity) {
      const entityIndex = this.entities.findIndex(e => e.businessEntityId === entity.businessEntityId)
      this.entities[entityIndex] = entity
    },

    entityUpdated (entity) {
      this.updatedExportCode = entity.exportCode
      this.resetDialogs(true)
      this.$emit('entity-mutated')
    },

    async deleteEntity (businessEntityId) {
      await this.deleteBusinessEntity(businessEntityId)
      this.$emit('entity-mutated')
    },

    openEntityForm (entity) {
      if (entity) {
        this.propEntity = entity
      }
      this.entityForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '40vw' })
    },

    openAccountForm () {
      this.accountForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    openEntityDelete (entityId) {
      this.deleteEntityId = entityId
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px' })
    },

    closeEntityDelete () {
      this.deleteEntityId = undefined
      this.resetDialogs()
    },

    async refreshAccounts (createdAccount) {
      this.loading = true
      try {
        await this.fetchAccounts()
        if (createdAccount) {
          this.createdAccount = createdAccount
        }
      } finally {
        this.loading = false
      }
    },

    resetDialogs (doRefresh) {
      this.deleteEntityId = undefined
      this.accountForm = false
      this.locationForm = false
      this.entityForm = false
      this.propEntity = undefined
      this.propBusinessEntityId = undefined
      this.addLocationEntity = false
      this.creatingDefaultAccount = false
      this.closeDialogsAtOrAbove(this.dialogId)
      if (doRefresh) {
        this.refreshAccounts()
      }
    },

    openLocationForm (businessEntityId, creatingDefaultAccount) {
      this.creatingDefaultAccount = creatingDefaultAccount
      this.addLocationEntityDialog = false
      this.propBusinessEntityId = businessEntityId
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
      this.locationForm = true
    },

    async closeLocationForm (locationResponse) {
      if (this.creatingDefaultAccount) {
        this.propEntity.accountId = locationResponse.accountId
        const requestObj = JSON.parse(JSON.stringify(this.propEntity))
        this.loading = true
        try {
          await this.updateBusinessEntity(requestObj)
          this.resetDialogs()
          this.$emit('entity-mutated')
        } finally {
          this.loading = false
        }
      } else {
        this.resetDialogs()
        this.$emit('entity-mutated')
      }
    },

    closeAddLocationEntityDialog () {
      this.addLocationEntityDialog = false
      this.$emit('entity-mutated')
    }
  }
}
</script>
