<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span class="title">
          Tract Summaries
        </span>
      </v-col>
      <v-col cols="12" class="mt-n6">
        <TractSummaries
        :tractSummaries="ticketRegisterObject.tractSummaries || []"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="title">
          Contract Summaries
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels multiple popout v-model="openPanels" tile>
          <v-expansion-panel v-for="(ticketSummary, index) in ticketRegisterObject.tractTickets || []" :key="`ticket-register-${index}`">
            <v-expansion-panel-header>
              <template #actions>
              <v-container>
                <v-row justify="end">
                  {{getTotalWeightForSummaryPanel(ticketSummary.ticketDetails)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalLoadsStringForSummaryPanel(ticketSummary.ticketDetails)}}
                </v-row>
              </v-container>
            </template>
            <TRPanelHeader
            :contractKeys="ticketSummary.contractKeys"
            color="grey darken-2"
            />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="lg_expansion-panel_wrapper">
                <TicketSummaryCard
                :ticketDetails="ticketSummary.ticketDetails"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-for="(ticketSummary, index) in ticketRegisterObject.transferTickets || []" :key="`ticket-register-transfer-${index}`">
            <v-expansion-panel-header>
              <template #actions>
              <v-container>
                <v-row justify="end">
                  {{getTotalWeightForSummaryPanel(ticketSummary.tickets)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalLoadsStringForSummaryPanel(ticketSummary.tickets)}}
                </v-row>
              </v-container>
            </template>
            <TRPanelHeader
            :contractKeys="ticketSummary.contractKeys"
            color="grey darken-2"
            isTransfer/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="lg_expansion-panel_wrapper">
                <TicketSummaryCard
                :ticketDetails="ticketSummary.tickets"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
export default {
  name: 'TicketRegister',

  props: {
    ticketRegisterObject: {
      type: Object,
      required: true
    }
  },

  components: {
    TractSummaries: () => import('@/components/settlements/accounts-payable-module/ticket-register/TractSummaries.vue'),
    TicketSummaryCard: () => import('@/components/settlements/accounts-payable-module/ticket-register/TicketSummaryCard.vue'),
    TRPanelHeader: () => import('@/components/settlements/accounts-payable-module/ticket-register/TRPanelHeader.vue')
  },

  data: () => ({
    openPanels: []
  }),

  created () {
    this.openPanels = this.ticketRegisterObject.tractSummaries.length + this.ticketRegisterObject.transferTickets.length === 1 ? [0] : []
  },

  methods: {
    getTitleForSummaryPanel (ticketSummary) {
      const { accountName, tractName, destinationName, settingName } = ticketSummary.contractKeys
      const ticketCount = ticketSummary.ticketDetails.length
      return `${tractName} \u2016 ${accountName} \u2016 ${destinationName} \u2016 ${settingName} \u2016 ${ticketCount} tickets`
    },

    getTotalWeightForSummaryPanel (tickets) {
      return `${tonStringFromPounds((tickets).reduce((a, b) => a + (b.inWeight - b.outWeight - b.defectWeight), 0))} tons`
    },

    getTotalLoadsStringForSummaryPanel (tickets) {
      const loadText = (tickets).length > 1 ? 'loads' : 'load'
      return `${(tickets).length} ${loadText}`
    }
  }
}
</script>
