import { ContractType, ContractMode } from '@/utils/Enumerations.js'
import theme from '@/plugins/theme'
import vuetify from '@/plugins/vuetify'

export const selectBreakpoint = (values, breakpoint) => {
  let currentBp = values.xs ?? values.sm ?? values.md ?? values.lg ?? values.xl
  for (const bp of ['xs', 'sm', 'md', 'lg', 'xl']) {
    currentBp = values[bp] ? values[bp] : currentBp
    if (bp === breakpoint) {
      return currentBp
    }
  }
}

export async function tickTock () {
  for (const fn of arguments) {
    await this.$nextTick()
    fn()
  }
}

export const uniqueDialogId = tag => `${tag}-${Math.floor(Math.random() * 0xFFFFFF).toString(16)}` // TODO MK: Refactor for the name "uniqueId" - doesn't need to be for dialogs

export const contractModeFor = (contractTypeValue) => {
  const contractType = ContractType.forInt(contractTypeValue)
  switch (contractType) {
    case ContractType.Production:
    case ContractType.WoodsSale:
      return ContractMode.Logs
    case ContractType.ByproductSale:
    case ContractType.ByproductPurchase:
      return ContractMode.Byproducts
    case ContractType.Transfer:
      return ContractMode.Transfers
    case ContractType.LogYardSale:
      return ContractMode.LogYardSale
  }
}

export const colorClassForContractType = (contractType, includeWhiteText = true) => {
  const whiteTextString = includeWhiteText ? ' white--text' : ''
  let color = ''
  switch (contractType) {
    case ContractType.ByproductSale.value:
    case ContractType.ByproductPurchase.value:
      color = 'byproduct'
      break
    case ContractType.Transfer.value:
      color = 'transfer'
      break
    case ContractType.LogYardSale.value:
      color = 'logyardsale'
      break
    case ContractType.Production.value:
    case ContractType.WoodsSale.value:
    default:
      color = 'primary'
  }

  return `${color} ${whiteTextString}`
}

export const colorClassForContractMode = (contractMode) => {
  return `${ContractMode.forInt(contractMode).details.color} white--text`
}

export const hexCodeForContractType = (contractType) => {
  switch (contractType) {
    case ContractType.ByproductPurchase.value:
    case ContractType.ByproductSale.value:
      return theme.byproduct
    case ContractType.Transfer.value:
      return theme.transfer
    case ContractType.LogYardSale.value:
      return theme.logyardsale
    case ContractType.Production.value:
    case ContractType.WoodsSale.value:
    default:
      return theme.primary
  }
}

export const hexCodeForContractMode = (contractMode) => {
  return theme[ContractMode.forInt(contractMode).details.color]
}

export const getMenuContainerStyle = (w, h) => {
  const maxHeight = h - 200
  const maxWidth = w - 100
  const minWidth = w > 500 ? 400 : w - 100

  const styleObject = w > 550 && h > 800
    ? {
      width: '400px'
    }
    : {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      maxHeight: `${maxHeight}px`,
      maxWidth: `${maxWidth}px`,
      minWidth: `${minWidth}px`
    }
  return styleObject
}

export const getDataTableSizeDetails = (heightString, showDense, dataLength) => {
  if (!heightString ||
  heightString === '' ||
  !dataLength ||
  vuetify.framework.breakpoint.smAndDown) {
    return {
      heightString: 'auto',
      resize: false,
      itemsPerPage: vuetify.framework.breakpoint.smAndDown ? 10 : 25
    }
  }

  const ROW_HEIGHT_PX = 48
  const ROW_HEIGHT_DENSE_PX = 32

  const rowHeight = showDense ? ROW_HEIGHT_DENSE_PX : ROW_HEIGHT_PX
  const viewHeight = window.innerHeight
  const heightCalculatesFromViewHeight = (/calc\(100vh - [0-9]+px\)/gm).test(heightString)

  let numericHeight

  if (heightCalculatesFromViewHeight) {
    // Height string is formatted like: 'calc(100vh - 300px)'
    const subtrahend = parseInt(
      heightString
        .match(/[0-9]+px/gm)[0]
        .replace('px', '')
    )
    numericHeight = viewHeight - subtrahend
  } else {
    // Height string is formatted like: '300px'
    let height = heightString.match(/[0-9]+px/gm)
    if (height) {
      height = parseInt(height[0].replace('px', ''))
      numericHeight = height
    } else {
    // Height string is neither of the above formats
      numericHeight = undefined
    }
  }

  const dataHeight = dataLength * rowHeight

  // If data occupies less space than default height, no need to resize
  const resize = !numericHeight || numericHeight < dataHeight

  // If default height is greater than the height of the data or less than 256px, just use automatic height
  const heightStringResult = !resize || (heightCalculatesFromViewHeight && numericHeight < 256)
    ? 'auto'
    : heightString

  const maxRowsInView = numericHeight ? numericHeight / rowHeight : -1
  const itemsPerPage = maxRowsInView > 50
    ? 100
    : maxRowsInView > 25
      ? 50
      : 25

  return {
    heightString: heightStringResult,
    resize: resize,
    itemsPerPage: itemsPerPage,
    canUseMinHeight: heightCalculatesFromViewHeight && numericHeight < 256
  }
}

export const autocompleteFilterChipStyle = {
  position: 'relative',
  right: '-115px',
  top: '24px',
  minHeight: '20px',
  marginTop: '-20px',
  maxWidth: 'calc(100% - 165px)',
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  zIndex: 1
}
