<template>
  <DataTable
    v-show="!activitiesLoading"
    :items.sync="entityActivities"
    :headers="activityHeaders"
    showExpand
    :expandIcon="showActions ? 'mdi-view-list' : 'mdi-dots-horizontal'"
    :actions="actions"
    hideHeader
    @create="$emit('create')"
    @refresh="$emit('refresh')"
    tableKey="activityId"
    :customCells="customColumns"
    containerClass="fit-to-panel"
    expandable
    :expansionThreshold="showActions ? 0 : 2"
    :expandOnCreation="!showActions"
  >
    <template #status="{item}">
      <Icon
        v-if="item.activityTemplate.costType !== 0"
        small
        icon-color="info"
        dataTestId="activity-cost-type"
        :icon="costIcons[item.activityTemplate.costType]"
        :tooltipText="CostTypeFromInt(item.activityTemplate.costType)"
      />
      <Icon
        v-if="item.recoveryMode !== RecoveryMode.None.value"
        small
        icon="mdi-cash-multiple"
        icon-color="success"
        dataTestId="activity-advance-recovery"
        :tooltipText="recoveryModeString(item)"
      />
      <Icon
      v-if="item.activityTemplate.specialization === TemplateSpecialization.Transfer.value"
      dataTestId="activity-transfer-icon"
      icon="mdi-transfer"
      :tooltipText="$t('transferActivity')"
      :small="false"
      />
      <Icon
      v-if="item.activityTemplate.specialization === TemplateSpecialization.Depletion.value"
      icon="mdi-wallet-bifold"
      :tooltipText="$t('depletionActivity')"
      iconColor="black"
      :small="false"/>
      <Icon
      v-if="item.activityTemplate.specialization === TemplateSpecialization.Penalty.value"
      icon="weight-alert"
      :tooltipText="$t('penalty')"
      :small="false"/>
    </template>
    <template #activity-name=" { item }">
      <span>{{item.activityTemplate.name}}</span>
    </template>
     <template #base-cost-rate="{ item }">
        <span v-if="item.baseCost !== null">
          {{ formatMoney(item.baseCost.rate) }}
        </span>
        <span v-else>
          {{$t('notAvailable')}}
        </span>
    </template>
    <template #gl-offset="{ item }">
      {{item.activityTemplate.glOffset ? item.activityTemplate.glOffset : $t('notAvailable')}}
    </template>
    <template #base-cost-pay-on="{ item }">
      <span v-if="item.baseCost !== null">
      {{payOnFromInt(item.baseCost.payOn)}}
      </span>
      <span v-else>
        {{$t('notApplicable')}}
      </span>
    </template>
    <template #base-cost-pay-by="{ item }">
      <span>
      {{ payByFromItem(item) }}
      </span>
    </template>
    <template #category="{ item }">
      <Icon
      :small="false"
      :icon="IconHelpers.getIconForObjectType(accountingCategoryFromInt(item.activityTemplate.category))"
      :tooltipText="accountingCategoryFromInt(item.activityTemplate.category)"/>
    </template>
    <template #actions="{ item }">
      <Icon
      margin="mr-2"
      icon="mdi-pencil"
      icon-color="success"
      dataTestId="activity-edit-button"
      :tooltipText="$t('editActivity')"
      @icon-clicked="emitTableAction(item, 'edit')"/>
      <Icon
      v-if="showAddDetail(item)"
      margin="mr-2"
      icon="mdi-plus"
      icon-color="success"
      dataTestId="activity-add-detail-button"
      :tooltipText="$t('createDetail')"
      @icon-clicked="createDetail(item)"/>
      <Icon
      icon="mdi-delete-forever"
      icon-color="error"
      dataTestId="activity-delete-button"
      :tooltipText="$t('deleteActivity')"
      :disabled="item.activityDetails && item.activityDetails.length !== 0"
      @icon-clicked="emitTableAction(item, 'delete')"/>
    </template>
    <template #counterparty="{ item }">
      <span v-if="counterpartySet(item)">
        <Icon
        iconColor="info"
        v-if="item.runtimeCounterparty !== null"
        icon="mdi-alpha-r-circle-outline"
        :tooltipText="`${$t('runtimeCounterparty')}: ${runtimeCounterparty(item.runtimeCounterparty)}`"/>
        {{ counterpartyString(item) }}
      </span>
      <span v-else>
        {{ $t('notSet') }}
        <Icon
        v-if="item.runtimeCounterparty !== null"
        icon="mdi-alert"
        iconColor="error"
        tooltipTcolor="error"
        :tooltipText="$t('runtimeFieldNotSpecified', { runtimeField: runtimeCounterparty(item.runtimeCounterparty) })"/>
      </span>
    </template>
    <template #details="{ item }">
      <v-row no-gutters align="center">
        <v-col class="mt-1" style="max-width: 200px;">
          <v-tooltip bottom color="black" v-for="detail in item.activityDetails.slice(0, 2)" :key="detail.activityDetailId">
            <template #activator="{on}">
              <v-chip small class="mb-1 mr-1" v-on="on">
                <span style="overflow: hidden;">
                  {{detail.product.name}}
                </span>
                <span class="ml-1">
                  {{formatMoney(detail.cost.rate)}}
                </span>
              </v-chip>
            </template>
            <span class="subtitle-1 white--text">
              {{detail.product.name}}: {{formatMoney(detail.cost.rate)}}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <v-container class="px-0" v-if="!showActions">
        <v-row dense>
          <v-col cols="12" sm="6" md="3" xl="2"
          v-for="detail in item.activityDetails.toSorted((a, b) => a.product.name.localeCompare(b.product.name))"
          :key="detail.activityDetailId">
            <v-card outlined class="fill-height">
              <v-card-title>
                <v-row dense align="center">
                  <v-col>
                    <span class="subtitle-1">{{detail.product.name}}</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="v-card__text pa-0">{{formatMoney(detail.cost.rate)}}</span>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-card style="max-width: 600px;" outlined>
          <ActivityDetailTable
            :contract="contract"
            :showActions="showActions"
            :activity="item"
            @detail-deleted="$emit('refresh')"
            @edit-detail="editDetail"
          >
          </ActivityDetailTable>
        </v-card>
      </v-container>

    </template>
  </DataTable>
</template>

<script>
import { PayOn, PayBy, AccountingCategory, CostType, RuntimeCounterparty, RecoveryMode, TemplateSpecialization } from '@/utils/Enumerations'
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters, mapActions } from 'vuex'
import ActivityHeaders from '@/headers/Activity.js'
import { TemplateSpecializationInfo } from './ActivityMapping'

import IconHelpers from '@/utils/IconHelpers'
export default {
  name: 'EntityActivityTable',

  props: {
    showActions: {
      type: Boolean,
      default: false
    },
    contract: {
      type: Object,
      default: undefined
    },
    businessEntityId: {
      type: Number,
      default: undefined
    },
    multiEntity: {
      type: Boolean,
      default: true
    },
    allActivities: {
      type: Array,
      default: undefined
    },
    tract: {
      type: Object,
      default: undefined
    }
  },

  components: {
    ActivityDetailTable: () => import('./activity-detail/DetailTable.vue'),
    Icon: () => import('../helper/Icon.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  data: () => ({
    TemplateSpecializationInfo,
    TemplateSpecialization,
    RecoveryMode,
    IconHelpers
  }),

  computed: {
    ...mapGetters('activity', ['activitiesLoading']),
    activityHeaders () { return !this.showActions ? ActivityHeaders.activityNoCRUDHeaders() : ActivityHeaders.activityCRUDHeaders() },
    customColumns () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'activity-name',
          value: 'activityTemplate.name'
        },
        {
          slotName: 'base-cost-rate',
          value: 'baseCost.rate'
        },
        {
          slotName: 'gl-offset',
          value: 'activityTemplate.glOffset'
        },
        {
          slotName: 'base-cost-pay-on',
          value: 'baseCost.payOn'
        },
        {
          slotName: 'base-cost-pay-by',
          value: 'baseCost.payBy'
        },
        {
          slotName: 'category',
          value: 'activityTemplate.category'
        },
        {
          slotName: 'effective-date',
          value: 'effectiveDate',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'counterparty',
          value: 'contractor.name'
        },
        {
          slotName: 'details',
          value: 'details'
        }
      ]
    },

    actions () {
      if (!this.showActions) {
        return []
      } else {
        return [
          {
            actionName: 'refresh',
            icon: 'mdi-refresh',
            text: this.$t('refresh')
          },
          {
            actionName: 'create',
            icon: 'mdi-plus',
            text: this.$t('new')
          }
        ]
      }
    },

    costIcons () {
      return ['mdi-alpha-u-circle-outline', 'mdi-alpha-p-circle-outline', 'mdi-alpha-s-circle-outline', 'mdi-alpha-n-circle-outline']
    },

    entityActivities () {
      if (this.multiEntity) {
        return this.allActivities.filter(activity => parseInt(activity.activityTemplate.businessEntityId) === this.businessEntityId)
      } else {
        return this.allActivities
      }
    }
  },

  methods: {
    ...mapActions('tract', ['fetchTract']),
    formatMoney,
    accountingCategoryFromInt: (ac) => AccountingCategory.fromInt(ac),
    CostTypeFromInt: (ct) => CostType.fromInt(ct),
    expand (activity) {
      if (this.expandedActivities.includes(activity)) {
        this.expandedActivities = []
      } else {
        this.expandedActivities = [activity]
      }
    },

    emitTableAction (activity, actionType) {
      const event = {
        activity: activity,
        type: actionType
      }
      this.$emit('table-event', event)
    },

    createDetail (activity) {
      this.emitTableAction(activity.activityId, 'new-detail')
    },

    editDetail (detail) {
      this.emitTableAction(detail, 'edit-detail')
    },

    payByFromItem ({ baseCost, activityTemplate: { specialization, category } }) {
      if (specialization === TemplateSpecialization.Penalty.value && category !== AccountingCategory.Receivable.value) return PayBy.Load.name
      if (baseCost?.payBy == null) return this.$t('notApplicable')
      return PayBy.fromInt(baseCost.payBy)
    },

    payOnFromInt (val) {
      return PayOn.fromInt(val)
    },

    recoveryModeString (val) {
      return this.$t('recoverAdvancesWithDetail', {
        detail: val.advanceNotes ?? RecoveryMode.fromInt(val.recoveryMode)
      })
    },

    counterpartySet (activity) {
      return activity.runtimeCounterparty === RuntimeCounterparty.Driver.value ||
        activity.contractor !== null ||
        (activity.runtimeCounterparty === RuntimeCounterparty.Landowner.value && this.tract?.landownerCount > 0)
    },

    counterpartyString (activity) {
      if (activity.runtimeCounterparty === RuntimeCounterparty.Driver.value) return this.$t('ticketDriver')
      return activity.contractor?.name ?? (this.tract.landOwnerAccountName || this.$t('multiple'))
    },

    runtimeCounterparty: (val) => RuntimeCounterparty.fromInt(val),

    showAddDetail (item) {
      return item.activityTemplate.specialization !== TemplateSpecialization.Transfer.value && (
        item.activityTemplate.specialization !== TemplateSpecialization.Penalty.value ||
        item.activityTemplate.category === AccountingCategory.Receivable.value)
    }
  }

}
</script>

<style>
.fit-to-panel {
  margin-top: -16px;
  padding: 1px;
  border-radius: 0px 4px 4px 0px;
  overflow: clip;
}
</style>
