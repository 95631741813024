<template>
  <v-card data-testid="activity-template-form">
    <v-card-title class="primary white--text">
      <span class="headline mr-2">{{ title }}</span>
      <Icon
      v-if="isEditing && template.specialization === TemplateSpecialization.Transfer.value"
      icon="mdi-transfer"
      iconColor="white"
      :tooltipText="$t('transferActivity')"
      :small="false"/>
      <Icon
      v-if="isEditing && template.specialization === TemplateSpecialization.Penalty.value"
      icon="weight-alert"
      :tooltipText="$t('penalty')"
      iconColor="white"
      :small="false"/>
      <Icon
      v-if="isEditing && template.specialization === TemplateSpecialization.Depletion.value"
      icon="mdi-wallet-bifold"
      :tooltipText="$t('depletionActivity')"
      iconColor="white"
      :small="false"/>
      <v-spacer />
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="templateForm"
        testId="save-activity-template"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="saveTemplate(false)"
      >
        <v-container fluid class="mt-2">
          <v-row>
            <v-col cols="12">
              <span class="title">{{ $t("templateDetails") }}</span>
            </v-col>
            <v-col cols="12" class="mb-n4" v-if="!isEditing && chosenCategory !== AccountingCategory.Receivable.value">
              <v-select
                :label="$t('specialization')"
                v-model="template.specialization"
                :items="availableSpecializations"
                item-text="name"
                item-value="value"
                style="width: 200px;">
                <template #item="{item}">
                  <Icon v-if="item.icon" :icon="item.icon" iconColor="black"/>
                  <span>{{item.name}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" :md="hideCostType ? 4 : 6">
              <v-text-field
                v-model="template.name"
                ref="firstField"
                :label="$t('name')"
                :rules="[rules.required]"
                data-testid="template-name"
                color="black"
                maxlength="64"
                counter
              ></v-text-field>
            </v-col>
            <v-col v-if="!hideCostType" cols="12" sm="6">
              <v-select
                v-model="template.costType"
                :label="$t('costType')"
                :items="costTypes"
                :rules="[rules.required]"
                item-color="primary"
                item-text="name"
                item-value="value"
                return-object
              />
            </v-col>
            <v-col cols="12" md="auto">
              <EntitySelector
                :entities="businessEntities"
                :initialEntity.sync="initialEntity"
                @entity-selected="entityChosen"
                class="mr-2"
              />
            </v-col>
            <v-col :style="{ minWidth: '350px' }">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="template.glCode"
                    :label="$t('glCode')"
                    :rules="[rules.required]"
                    data-testid="template-gl-code"
                    color="black"
                    counter
                    maxlength="32"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="template.glOffset"
                    :label="$t('glCodeOffset')"
                    :rules="glOffsetRequired ? [rules.required] : []"
                    data-testid="template-gl-offset"
                    color="black"
                    maxlength="32"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" xs="12" v-if="showBaseCost">
              <span class="title">{{ $t("baseCost") }}</span>
            </v-col>
            <v-col cols="12" xs="12" v-if="showBaseCost">
              <BaseCostForm
                :propBaseCost="template.baseCost"
                :editing="isEditing"
                :accountingCategory="chosenCategory"
                @base-cost-changed="baseCostChanged"
                @is-by-load="byLoadChanged"
                :isBaseCostForm="true"
              />
            </v-col>
            <v-col cols="12" sm="3" xl="2" v-if="showBaseCost">
              <v-select
                v-model="template.modifier"
                :items="modifiers"
                :disabled="disabledModifierHint !== undefined"
                :hint="disabledModifierHint"
                :label="$t('modifier')"
                data-testid="template-modifier"
                item-color="primary"
                item-text="name"
                item-value="value"
                return-object
                persistent-hint
                color="black"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="isEditing && !transferOrNonReceivablePenalty">
              <ProductDetails
              :propProductDetails="template.details"
              @new-product-detail-clicked="openProductDetailForm"
              @edit-template-detail-clicked="openProductDetailForm"
              @delete-template-detail-clicked="confirmDeleteTemplateDetail"
              />
            </v-col>
          </v-row>
        </v-container>
        <template #right-action>
          <v-row class="mr-2">
            <v-col cols="auto" v-if="chosenCategory === AccountingCategory.Receivable.value">
              <v-checkbox
              :label="$t('incursPenalty')"
              v-model="incursPenalty"
              v-if="chosenCategory === AccountingCategory.Receivable.value"/>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
              :disabled="transferOrNonReceivablePenalty"
              :label="$t('tractLevelCapture')"
              color="primary"
              data-testid="template-tract-level-capture"
              v-model="template.tractLevelCapture"
              />
            </v-col>
            <v-col cols="auto" id="applyChangesToExistingContracts" v-if="isEditing && !transferOrNonReceivablePenalty">
              <v-checkbox
              :label="$t('applyChangesToExistingContracts')"
              data-testid="template-apply-check"
              v-model="applyChangesDownstream"
              />
            </v-col>
            <v-col cols="auto" v-if="isEditing">
              <v-checkbox
              :label="$t('active')"
              v-model="activityStatus"
              />
            </v-col>
          </v-row>
        </template>
      </FormWrapper>
    </v-card-text>
    <v-dialog width="400" v-model="confirmDialog">
      <ConfirmDialog
        :title="$t('confirmUpdate')"
        :body="$t('confirmActivityUpdate')"
        :confirmText="$t('yes')"
        @confirm="saveTemplate(true)"
        @cancel="confirmDialog = false"
      />
    </v-dialog>
    <v-dialog width="400" v-model="confirmDeleteDetailDialog">
      <ConfirmDialog
        v-if="focusedDetail"
        :title="$t('deleteTemplateDetail', { productName: focusedDetail.product })"
        :body="$t('deleteTemplateDetailBody')"
        @confirm="deleteTemplateConfirmed"
        @cancel="closeDialogs"
      />
    </v-dialog>
    <v-dialog v-model="templateDetailForm"
    width="600px">
      <ProductDetailForm
      v-if="templateDetailForm"
      :propTemplateDetail="focusedDetail"
      :existingDetails="template.details"
      @close="closeDialogs"
      @template-detail-updated="templateDetailMutated"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import * as fieldRules from '@/utils/rules'
import { mapActions, mapGetters } from 'vuex'
import { ActivityModifier, CostType, ActivityStatus, PayOn, PayBy, AccountingCategory, TemplateSpecialization } from '@/utils/Enumerations.js'
import { TemplateSpecializationInfo } from '../../activity/ActivityMapping'

export default {
  name: 'TemplateForm',

  components: {
    BaseCostForm: () => import('@/components/activity/BaseCostForm.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    ProductDetails: () => import('@/components/settings-components/activity-template/ProductDetails.vue'),
    ProductDetailForm: () => import('@/components/settings-components/activity-template/ProductDetailForm.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propTemplate: {
      type: Object,
      default: undefined
    },
    initialEntity: {
      type: Object,
      default: undefined
    },
    chosenCategory: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    TemplateSpecialization,
    TemplateSpecializationInfo,
    AccountingCategory,
    confirmDialog: false,
    confirmDeleteDetailDialog: false,
    templateDetailForm: false,
    applyChangesDownstream: false,
    focusedDetail: undefined,
    rules: fieldRules.default.rules,
    maxBaseRate: fieldRules.MAX_ACTIVITY_RATE,
    modifiers: ActivityModifier.enums,
    statuses: ActivityStatus.enums,
    savedModifier: ActivityModifier.None,
    template: {
      name: '',
      glCode: '',
      glOffset: '',
      activityStatus: ActivityStatus.Active.value,
      category: AccountingCategory.Payable.value,
      modifier: ActivityModifier.None,
      tractLevelCapture: false,
      businessEntityId: null,
      costType: null,
      details: [],
      baseCost: {
        rate: 0.00,
        payBy: PayBy.Weight.value,
        payOn: PayOn.Net.value
      },
      specialization: TemplateSpecialization.None.value
    }
  }),

  mounted () {
    this.$nextTick(this.$refs.firstField?.focus)
  },

  computed: {
    ...mapGetters('user', ['businessEntities']),
    title () {
      return this.isEditing
        ? this.$t('editingActivityTemplate', { type: AccountingCategory.fromInt(this.chosenCategory) })
        : this.$t('creatingActivityTemplate', { type: AccountingCategory.fromInt(this.chosenCategory) })
    },

    isEditing () {
      return this.propTemplate?.activityTemplateId !== undefined
    },

    mutateAccrualConfigs () {
      return !this.isEditing && this.chosenCategory === AccountingCategory.Accrual.value
    },

    glOffsetRequired () {
      return this.template.category === AccountingCategory.Receivable.value ||
        this.template.category === AccountingCategory.Accrual.value
    },

    saveBtnText () {
      return this.isEditing
        ? this.$t('saveChanges')
        : this.$t('createNewTemplate')
    },

    costTypes () {
      return CostType.enums.filter(ct => ct !== CostType.Unspecified && (ct !== CostType.Stumpage || this.template.specialization !== TemplateSpecialization.Penalty.value))
    },

    hideCostType () {
      return this.template.category === AccountingCategory.Receivable.value
    },

    disabledModifierHint () {
      return this.template.baseCost?.payBy === PayBy.Load.value
        ? this.$t('disTemplateModifierHint')
        : undefined
    },

    specialization () {
      if (this.propTemplate) return this.propTemplate.specialization
      if (this.template.category === AccountingCategory.Accrual.value && this.template.transfer) {
        return TemplateSpecialization.Transfer.value
      } else if (this.template.overweightPenalty) {
        return TemplateSpecialization.Penalty.value
      }
      return TemplateSpecialization.None.value
    },

    availableSpecializations () {
      return Object.values(this.TemplateSpecializationInfo)
        .filter(tsi => !tsi.categories || tsi.categories.map(ac => ac.value)
          .includes(this.chosenCategory))
        .map(tsi => tsi.enum)
    },

    activityStatus: {
      get () {
        return this.template.activityStatus === ActivityStatus.Active.value
      },
      set (status) {
        if (status) {
          this.template.activityStatus = ActivityStatus.Active.value
        } else {
          this.template.activityStatus = ActivityStatus.Inactive.value
        }
      }
    },

    showBaseCost () {
      return !this.transferOrNonReceivablePenalty
    },

    incursPenalty: { // For receivables, where incurring a penalty isn't a "specialization" from the user's perspective
      get () {
        return this.template.specialization === TemplateSpecialization.Penalty.value
      },
      set (incursPenalty) {
        if (incursPenalty) {
          this.template.specialization = TemplateSpecialization.Penalty.value
        } else {
          this.template.specialization = TemplateSpecialization.None.value
        }
      }
    },

    transferOrNonReceivablePenalty () {
      return this.template.specialization === TemplateSpecialization.Transfer.value ||
      (this.template.specialization === TemplateSpecialization.Penalty.value && this.chosenCategory !== AccountingCategory.Receivable.value)
    }
  },

  watch: {
    confirmDeleteDetailDialog (val) {
      if (!val) {
        this.focusedDetail = undefined
      }
    },

    templateDetailForm (val) {
      if (!val) {
        this.focusedDetail = undefined
      }
    },

    'template.specialization': {
      handler (_) {
        if (this.transferOrNonReceivablePenalty) {
          this.template.tractLevelCapture = false
        }
      },
      deep: true
    }
  },

  async created () {
    this.template.businessEntityId = this.initialEntity?.businessEntityId
    this.template.category = this.chosenCategory

    if (this.isEditing) {
      this.template = JSON.parse(JSON.stringify(this.propTemplate))
      this.selectedSpecialization = TemplateSpecialization.forInt(this.template.specialization)
      this.template.costType = CostType.forInt(this.propTemplate.costType)
      this.template.modifier = ActivityModifier.forInt(this.propTemplate.modifier)
      this.activityStatus = this.propTemplate.activityStatus === ActivityStatus.Active.value
    }
  },

  methods: {
    ...mapActions('activity-templates', [
      'createActivityTemplate',
      'updateActivityTemplate',
      'updateTemplateDetail',
      'deleteTemplateDetail',
      'createTemplateDetail',
      'fetchTemplateDetails'
    ]),

    baseCostChanged (baseCost) { this.template.baseCost = baseCost },
    entityChosen ({ businessEntityId }) { this.template.businessEntityId = businessEntityId },

    async refreshDetails () {
      const details = await this.fetchTemplateDetails(this.template.activityTemplateId)
      this.template.details = details
    },

    confirmDeleteTemplateDetail (templateDetail) {
      this.focusedDetail = templateDetail
      this.confirmDeleteDetailDialog = true
    },

    async deleteTemplateConfirmed () {
      await this.deleteTemplateDetail({
        activityTemplateId: this.template.activityTemplateId,
        activityTemplateDetailId: this.focusedDetail.activityTemplateDetailId
      })
      this.closeDialogs()
      this.refreshDetails()
    },

    closeDialogs () {
      this.templateDetailForm = false
      this.confirmDeleteDetailDialog = false
      this.focusedDetail = undefined
    },

    openProductDetailForm (templateDetail = undefined) {
      if (templateDetail !== undefined) {
        this.focusedDetail = JSON.parse(JSON.stringify(templateDetail))
      }
      this.templateDetailForm = true
    },

    async templateDetailMutated (templateDetail) {
      if (templateDetail.activityTemplateDetailId === undefined) {
        await this.createTemplateDetail({
          templateDetail: templateDetail,
          activityTemplateId: this.template.activityTemplateId
        })
      } else {
        await this.updateTemplateDetail({
          templateDetail: templateDetail,
          activityTemplateId: this.template.activityTemplateId
        })
      }

      this.closeDialogs()
      this.refreshDetails()
    },

    async saveTemplate (forceChanges) {
      if (!this.$refs.form.$refs.templateForm.validate()) {
        if (this.template.baseCost.rate > this.maxBaseRate) {
          this.setSnackError(this.$t('invalidActivityRate'))
        }
        return
      }

      if (this.template.specialization === TemplateSpecialization.Transfer.value && this.template.costType !== CostType.Production && this.template.costType !== CostType.NonInventory) {
        this.setSnackError(this.$t('transferActivityCostTypeError'))
        return
      }

      if (this.template.specialization === TemplateSpecialization.Depletion.value) {
        if (this.template.costType.value !== CostType.Stumpage.value) {
          this.setSnackError(this.$t('depletionActivityMustHaveStumpageCostType'))
          return
        }

        if (this.template.baseCost.payBy !== PayBy.Weight.value) {
          this.setSnackError(this.$t('depletionActivityMustPayByWeight'))
          return
        }

        if (this.template.baseCost.payOn !== PayOn.Gross.value) {
          this.setSnackError(this.$t('depletionActivityMustPayOnGross'))
          return
        }

        if (this.template.modifier !== ActivityModifier.None) {
          this.setSnackError(this.$t('depletionActivityCannotHaveModifier'))
          return
        }
      }

      const formattedTemplate = this.getFormattedTemplate()

      if (this.isEditing) {
        if (this.isBaseCostChanged() && this.applyChangesDownstream && !forceChanges) {
          this.confirmDialog = true
          return
        }

        const updateRequest = {
          template: formattedTemplate,
          applyChangesDownstream: this.applyChangesDownstream
        }

        await this.updateActivityTemplate(updateRequest)
        this.$emit('template-mutated')
      } else {
        await this.createActivityTemplate(formattedTemplate)
        this.$emit('template-mutated')
      }
    },

    isBaseCostChanged () {
      return (
        this.template.baseCost?.rate !== this.propTemplate.baseCost?.rate ||
        this.template.baseCost?.payOn !== this.propTemplate.baseCost?.payOn ||
        this.template.baseCost?.payBy !== this.propTemplate.baseCost?.payBy
      )
    },

    getFormattedTemplate () {
      return {
        activityTemplateId: this.isEditing ? this.template.activityTemplateId : null,
        baseCost: this.showBaseCost ? this.template.baseCost : null,
        category: this.template.category,
        glCode: this.template.glCode,
        glOffset: this.template.glOffset,
        costType: this.template.category === AccountingCategory.Receivable.value ? CostType.Unspecified.value : this.template.costType.value,
        businessEntityId: this.template.businessEntityId,
        name: this.template.name,
        tractLevelCapture: this.template.tractLevelCapture,
        activityStatus: this.template.activityStatus,
        modifier: this.showBaseCost ? this.template.modifier.value : ActivityModifier.None.value,
        specialization: this.template.specialization
      }
    },

    byLoadChanged (isByLoad) {
      if (isByLoad) {
        this.savedModifier = this.template.modifier
        this.template.modifier = ActivityModifier.None
      } else {
        this.template.modifier = this.savedModifier
      }
    },

    transferUpdated (v) {
      if (v) this.template.overweightPenalty = false
    },

    overweightPenaltyUpdated (v) {
      if (v) this.template.transfer = false
    }
  }
}
</script>
