import i18n from '../../i18n'

export const getRelevantUrlComponent = (url) => {
  const pathComponents = url.split('/')
  // Is the last component an id (i.e. a number) or not?
  const lastComponentOffset = isNaN(parseInt(pathComponents[pathComponents.length - 1])) ? 1 : 2
  // Use the offset to get the last relevant component.
  let lastComponent = (pathComponents[pathComponents.length - lastComponentOffset]).toLowerCase()
  // Remove the last character if it is an s.
  if (lastComponent.charAt(lastComponent.length - 1) === 's') {
    lastComponent = lastComponent.slice(0, lastComponent.length - 1)
  }
  // Return the dictionary value or the last component.
  return renameDictionary[lastComponent] || lastComponent
}

const renameDictionary = {
  tracttype: i18n.t('tractType'),
  tractpayable: i18n.t('tractPayable'),
  tractcontent: i18n.t('cruiseData'),
  tractharvest: i18n.t('tractHarvest'),
  journalentrie: i18n.t('accrual'),
  ticketexport: i18n.t('ticketExport'),
  defectdefinition: i18n.t('defectDefinition'),
  businessentitie: i18n.t('entity'),
  timeinyard: i18n.t('timeInYard'),
  tractdeliveredcost: i18n.t('deliveredTractCosts'),
  ticketsummarie: i18n.t('ticketSummaries'),
  harvestbyregion: i18n.t('harvestByRegion'),
  deviceregistrations: i18n.t('deviceRegistrations'),
  applicationuser: i18n.t('user'),
  ticketfinancialrecoveriesexport: i18n.t('financialRecoveryExport'),
  ticketfinancialsexport: i18n.t('financialExport'),
  ticketimage: i18n.t('ticketImage'),
  companyinfo: i18n.t('companyInfo'),
  servertime: i18n.t('serverTime'),
  activitytemplate: i18n.t('activityTemplate'),
  recoverie: i18n.t('recovery'),
  activitydetail: i18n.t('activityDetail'),
  activitie: i18n.t('activity'),
  userpreference: i18n.t('userPreferences'),
  millinfo: i18n.t('millInformation'),
  consumptionbatche: i18n.t('consumptionBatch'),
  postbatche: i18n.t('postBatch'),
  jecsvexport: i18n.t('accrualCsvExport'),
  arcsvexport: i18n.t('receivableCsvExport'),
  apcsvexport: i18n.t('payableCsvExport'),
  tractpayment: i18n.t('tractPayment'),
  journalentrycalculation: i18n.t('accrualCalculations'),
  journalentryregister: i18n.t('accrualRegister'),
  receivableregister: i18n.t('receivableRegister'),
  arproductionsummary: i18n.t('receivableProductionSummary'),
  producerproduction: i18n.t('producerProduction'),
  ticketregister: i18n.t('ticketRegister'),
  paymentregister: i18n.t('paymentRegister'),
  payperiod: i18n.t('payPeriod'),
  fiscalyear: i18n.t('fiscalYear'),
  addresse: i18n.t('address'),
  diameterweight: i18n.t('diameterWeights'),
  modification: i18n.t('advanceModification'),
  activitytemplatedetail: i18n.t('templateDetail'),
  exportbatche: i18n.t('exportBatch'),
  activitybuilder: i18n.t('activityBuilder'),
  accountpayable: i18n.t('accountPayment'),
  insurancepolicie: i18n.t('insurancePolicy'),
  receiversetting: i18n.t('receiverSettings'),
  deviceregistration: i18n.t('deviceRegistration'),
  penaltydefinition: i18n.t('penaltyDefinition'),
  penaltythreshold: i18n.t('penaltyThreshold')
}
